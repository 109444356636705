import { format, isBefore } from 'date-fns'
import csLocale from 'date-fns/locale/cs'
import cn from 'classnames'

interface Props {
  title: string
  date: string
}

const Event = ({ title, date }: Props) => {
  const formattedDate = format(new Date(date), 'dd.MM.yyyy HH:mm eeeeee', {
    locale: csLocale,
  })
  const isDue = isBefore(new Date(date), new Date())
  return (
    <div className="mb-2">
      <p className={cn('p1 m-0', { '!text-neutral-400': isDue })}>
        <span className={cn('text-main mr-1', { 'text-neutral-400': isDue })}>
          {formattedDate}
        </span>{' '}
        {title}
      </p>
    </div>
  )
}

export default Event
