import { Router } from '@reach/router'
import Calendar from '../components/Calendar'

const Kalendar = () => {
  return (
    <Router>
      <Calendar {...({ path: '/kalendar/:page' } as PagePropsPagination)} />
      <Calendar {...({ path: '/kalendar/' } as PagePropsPagination)} />
    </Router>
  )
}
export default Kalendar
