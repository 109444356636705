import { useQuery, gql } from '@apollo/client'

export interface Event {
  id: string
  title: string
  date: string
}

interface EventCollectionQueryVars {
  limit: number
  skip: number
}

interface EventCollectionQueryData {
  eventCollection: {
    items: Event[]
  }
}

const EVENTS_PER_PAGE = 20
export const getEventVariables = (page: number) => {
  const skip = (page - 1) * EVENTS_PER_PAGE
  return {
    skip,
    limit: EVENTS_PER_PAGE,
  }
}
export const getNumberOfPages = (numberOfGalleries: number) =>
  Math.ceil(numberOfGalleries / EVENTS_PER_PAGE)

const QUERY = gql`
  query EventCollection($limit: Int!, $skip: Int!) {
    eventCollection(limit: $limit, skip: $skip, order: date_DESC) {
      items {
        title
        date
      }
    }
  }
`

const useGetEvents = ({ skip, limit }: EventCollectionQueryVars) =>
  useQuery<EventCollectionQueryData, EventCollectionQueryVars>(QUERY, {
    variables: { skip, limit },
  })

export default useGetEvents

interface EventCollectionHomeQueryVars {
  date: Date
}

interface EventCollectionHomeQueryData extends EventCollectionQueryData {
  dueEvents: {
    items: Event[]
  }
}

const QUERY_HOME = gql`
  query EventCollectionHome($date: DateTime) {
    eventCollection(where: { date_gt: $date }, order: date_ASC, limit: 3) {
      items {
        title
        date
      }
    }
    dueEvents: eventCollection(
      where: { date_lt: $date }
      order: date_DESC
      limit: 1
    ) {
      items {
        title
        date
      }
    }
  }
`

export const useGetEventsHome = ({ date }: EventCollectionHomeQueryVars) =>
  useQuery<EventCollectionHomeQueryData, EventCollectionHomeQueryVars>(
    QUERY_HOME,
    {
      variables: { date },
    }
  )
