import { useQuery, gql } from '@apollo/client'

interface EventCollectionQueryData {
  eventCollection: {
    total: number
  }
}

const QUERY = gql`
  query {
    eventCollection {
      total
    }
  }
`
const useGetEventsCount = () => useQuery<EventCollectionQueryData>(QUERY)

export default useGetEventsCount
