import { Link } from 'gatsby'
import cn from 'classnames'

interface Props {
  text: string
  to: string
  isActive: boolean
}

const PaginationLink = ({ text, to, isActive }: Props) => {
  return (
    <Link
      to={to}
      className={cn(
        'mx-0.5 inline-block px-2 py-2 lg:px-3 lg:py-2 text-text hover:text-white hover:bg-main text-lg no-underline font-Roboto text-light rounded',
        { 'bg-main': isActive, 'text-white': isActive }
      )}
    >
      {text}
    </Link>
  )
}

export default PaginationLink
