import PaginationLink from './PaginationLink'
import {
  getPhotosDynamicRoute,
  getNewsDynamicRoute,
  getEventsDynamicRoute,
  getCallsDynamicRoute,
  getPhotosChildrenDynamicRoute,
} from '../utils/routes'

interface Props {
  numberOfPages: number
  page: number
  type: 'photos' | 'photosChildren' | 'news' | 'events' | 'calls'
}

const Pagination = ({ numberOfPages, page, type }: Props) => {
  let getRoute = getPhotosDynamicRoute

  if (type === 'news') {
    getRoute = getNewsDynamicRoute
  }

  if (type === 'events') {
    getRoute = getEventsDynamicRoute
  }

  if (type === 'calls') {
    getRoute = getCallsDynamicRoute
  }

  if (type === 'photosChildren') {
    getRoute = getPhotosChildrenDynamicRoute
  }

  if (!numberOfPages || numberOfPages === 1) {
    return null
  }

  return (
    <div className="mt-4 flex justify-center">
      {Array.from({ length: numberOfPages }).map((_, i) => {
        const currPage = i + 1
        return (
          <PaginationLink
            to={getRoute(currPage)}
            text={`${currPage}`}
            isActive={currPage === page}
            key={currPage}
          />
        )
      })}
    </div>
  )
}

export default Pagination
