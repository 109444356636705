import Screen from './Screen'
import useEvents from '../hooks/useEvents'
import Pagination from './Pagination'
import Event from './Event'

const Calendar = ({ uri, page = '1', ...rest }: PagePropsPagination) => {
  const parsedPage = parseInt(page)
  const { events, numberOfPages } = useEvents(parsedPage)

  return (
    <Screen uri={uri} {...rest}>
      <h2 className="h2-medium divider pb-4 mb-4">Kalendář akcí</h2>
      {events.map(({ date, title }) => (
        <Event key={date} date={date} title={title} />
      ))}
      <Pagination
        numberOfPages={numberOfPages}
        page={parsedPage}
        type="events"
      />
    </Screen>
  )
}

export default Calendar
